import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import RechargeStation from '../../sections/app/charge/RechargeStation'
import RechargeStatus from '../../sections/app/charge/RechargeStatus'
import RechargeConect from '../../sections/app/charge/RechargeConect'

import { withStore } from '../../_store/StoreContext'


// ----------------------------------------------------------------------

function AppCharge(store) {
  const navigate = useNavigate()
  const st = store.store
  const { stations, chargeOngoing, chargeWaiting } = st

  const [chargeState, setChargeState] = useState("station")
  const [stationID, setStationID] = useState('0')
  const [code, setCode] = useState('')

  useEffect(() => {
    _initializer()
      .catch(console.error)

    const interval = setInterval(() => tick(), 10*1000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
      
  }, []);

  const _initializer = async () => {
    await st.stationsGetAll()
    await st.chargesAll()
    await st.getChargeWaiting()
    await st.getChargeOngoing()
    if(chargeWaiting){
      setChargeState('conect')
      st.setChargeState('conect')
    }
    if(chargeOngoing){
      setChargeState('status')
      st.setChargeState('conect')
    }
  }

  const tick = async () => {
    console.log("tick", new Date())
    await st.chargesAll()
    const chargeWaitingTick = await st.getChargeWaiting()
    const chargeOngoingTick = await st.getChargeOngoing()
    const chargeStateTick = await st.getChargeState()

    if(chargeStateTick ==='conect' && chargeWaitingTick === null && chargeOngoingTick === null){
      setChargeState('station')
      st.setChargeState('station')
    }
    if(chargeStateTick ==='conect' && chargeOngoingTick != null){
      setChargeState('status')
      st.setChargeState('status')
    }
    if(chargeStateTick ==='status' && chargeOngoingTick === null && chargeOngoingTick === null){
      setChargeState('station')
      st.setChargeState('station')
    }
  }

  const handleCancel = async () => {
    setStationID('0')
    setChargeState('station')
    st.setChargeState('station')
    navigate('/app', { replace: true })
  }

  const handleUnlock = async () => {
    // testar se o codigo digitado esta certo
    await st.chargeStart(stationID, code)
    await st.chargesAll()
    setChargeState('conect')
    st.setChargeState('conect')
  }

  const handleStatus = async () => {
    await st.chargesAll()
    setChargeState('status')
    st.setChargeState('status')
  }

  const handleStopCharge = async () => {
    await st.chargeStop(chargeOngoing.uuid)
    setChargeState('station')
    st.setChargeState('station')
  }

  return (
  chargeState ==='status' &&
  <RechargeStatus chargeOngoing={chargeOngoing} onClickStopCharge={handleStopCharge}/> ||
  chargeState ==='station' && 
  <RechargeStation 
    stations={stations}
    stationID={stationID} 
    setStationID={setStationID}
    setCode={setCode}
    onClickUnlock={handleUnlock}  
    onCancel={handleCancel}
  /> ||
  chargeState ==='conect' &&
  <RechargeConect onClickOK={handleStatus}/>
  )
}

export default withStore(AppCharge)
