// @mui
import { styled } from '@mui/material/styles'
import { Typography, Button, Grid } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  background: 'var(--Purple-Purple-100, #F5F6FF)',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 2),
  minHeight: '920px',
}))

const Leftdiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '476px',
  minHeight: '570px',
  padding: '32px 48px 48px 48px',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'var(--White, #FFF)',
  borderRadius: '30px',
  marginRight: '24px',
}))

const Rightdiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '629px',
  minHeight: '259px',
  padding: '32px 48px',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'var(--White, #FFF)',
  borderRadius: '30px',
}))

const Label = styled('div')(({ theme }) => ({
  color: 'var(--Green-Green-600, #000300)',
  textAlign: 'center',
  fontFeatureSettings: "'liga' off",
  fontFamily: 'Ubuntu',
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '140%', /* 39.2px */
}))


const Text = styled('div')(({ theme }) => ({
  display: 'flex',
  width:'100%',
  maxWidth: '476px',
  flexDirection: 'row',
  color: 'var(--Texto, #111638)',
  fontFeatureSettings: "'liga' off",
  textAlign:'left',
  fontFamily: 'Ubuntu',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '140%', /* 28px */
}))

const CheckCircleIcon = styled(CheckCircleOutlineIcon)({
  width: 22,
  height: 22,
  marginRight:'16px',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
// ----------------------------------------------------------------------

export default function Station() {
  return (
    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" sx={{ color:'#36209E', marginBottom: 6}}>
          Planos
        </Typography>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} sx={{ marginBottom:'62px' }}>
          <Grid item>
            <Leftdiv>
              <Label sx={{ marginBottom: 6}}>
                Assine a plataforma
              </Label>
              <Text sx={{ marginBottom: 4}}>
                <CheckCircleIcon/>
                Implantação imediata              
              </Text>
              <Text sx={{ marginBottom: 4}}>
                <CheckCircleIcon/>
                Acesso ao software de gestão
              </Text>
              <Text sx={{ marginBottom: 4}}>
                <CheckCircleIcon/>
                Aplicativo para o motorista
              </Text>
              <Text sx={{ marginBottom: 4}}>
                <CheckCircleIcon/>
                Usuários e motoristas ilimitados
              </Text>
              <Text sx={{ marginBottom: 4}}>
                <CheckCircleIcon/>
                Carregadores ilimitados
              </Text>
              <Button href="https://qxdys8vn.forms.app/conectave-form" size='large' variant="outlined" color="primary">
                Fale conosco
              </Button>
              
            </Leftdiv>
          </Grid>
          <Grid item>
            <Rightdiv sx={{minHeight:'287px',  marginBottom: 2}}>
              <Label sx={{ marginBottom: 2}}>
                White-label
              </Label>
              <Text sx={{ marginBottom: 4}}>
                Para usar o software com sua marca própria. Ideal para quem quer criar uma marca própria de eletropostos e rede de carregadores.
              </Text>
              <Button href="https://qxdys8vn.forms.app/conectave-form" size='large' variant="outlined" color="primary">
                Fale conosco
              </Button>
            </Rightdiv>
            <Rightdiv>
              <Label sx={{ marginBottom: 2}}>
                Compre seu carregador
              </Label>
              <Text sx={{ marginBottom: 4}}>
                Quer ajuda dos nossos especialistas para escolher o carregador mais indicado para você?  Vamos conversar!
              </Text>
              <Button href="https://qxdys8vn.forms.app/conectave-form" size='large' variant="outlined" color="primary">
                Fale conosco
              </Button>
            </Rightdiv>
          </Grid>
        </Grid>
    </ContentStyle>
  )
}
