import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Stack, Typography, Button, Box, Grid } from '@mui/material'


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  background:'linear-gradient(96.71deg, #54ED8A 4.03%, #543ACB 85.08%)',
  height: '1400px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 2),
  minHeight: '50vh',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    height: '760px',
  }
}))

const Card = styled('div')(()=>({
  background:'#ffffff',
  minWidth:'325px', 
  width:'325px',
  minHeight: '312px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  borderRadius: '30px',
  padding:'26px',
}))

// ----------------------------------------------------------------------

export default function HowWorks() {
  return (
    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
      <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Typography variant="h3" sx={{ color:'#ffffff'}}>
          Para carregar seu carro, como funciona
        </Typography>
        <Typography sx={{maxWidth:550, fontSize:16, marginBottom:6, color:'#ffffff'}} >
          Com poucos clicks você pode carregar seu carro de forma rápida e sustentável.
        </Typography>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4} sx={{ marginBottom:'62px' }}>
            <Grid item>
              <Card>
                <Box component="img" alt={'icon_car'} src={'/static/icons/car_howWorks.svg'} sx={{ width: 72, height: 72, marginBottom:'32px' }} />
                <Typography gutterBottom align="left" variant="h6" color='#36209E'>
                  1- Cadastro e login
                </Typography>
                <Typography sx={{width:266, fontSize:16, color:'#000'}} >
                  Faça seu cadastro e login na plataforma.
                </Typography>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <Box component="img" alt={'icon_card'} src={'/static/icons/card_howWorks.svg'} sx={{ width: 72, height: 72, marginBottom:'32px' }} />
                <Typography gutterBottom align="left" variant="h6" color='#36209E'>
                  2- Encontre a estação mais próxima
                </Typography>
                <Typography sx={{width:266, fontSize:16, color:'#000'}} >
                  Localize a estação mais próxima de você e siga os passos para desbloquear.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        <Button to="/login" component={RouterLink} size='large' variant="contained" color="secondary">
          Comece a usar agora
        </Button>
      </Stack>
    </ContentStyle>
  )
}
