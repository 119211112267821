import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, AppBar, Toolbar, Stack, Button, Link} from '@mui/material'
// components
import Logo from '../../../components/Logo'
// store 
import { withStore } from '../../../_store/StoreContext'

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 76

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  width: `100%`
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  height: '80px',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

// ----------------------------------------------------------------------
function HomeNavbar(store) {
  const navigate = useNavigate()
  const st = store.store

  const check = async () => {
    await st.organization()
    if (JSON.parse(localStorage.getItem('token'))) {
      navigate('/app', { replace: true })
    } else {
      navigate('/login', { replace: true })
    }
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ width: '279px', px: 2.5, py: 3, display: 'inline-flex' }}>
          <Logo />
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Link variant="subtitle2" component={RouterLink} to="/register">
            Registrar
          </Link>
          <Button 
            size="medium" 
            variant="contained" 
            component={RouterLink}
            onClick={() => check()}
          >
            Login
          </Button>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}

export default withStore(HomeNavbar)