// @mui
import { styled } from '@mui/material/styles'
import { Container, Typography } from '@mui/material'
// components
import Page from '../../components/Page'

const ContentStyle = styled('div')(({ theme }) => ({
  background:'#36209E',
  // minHeight: '750px',
  minHeight: '250px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: '8px',
}))

// ----------------------------------------------------------------------

export default function AppSupport() {
  return (
    <Page title='AppSuporte' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
        <Typography variant='h2' sx={{ pb:2 }} color='secondary'>
            Suporte
          </Typography>
          <Typography variant='h4' color='secondary'>
            Telefone / whatsapp: 
          </Typography>
          <Typography variant='h3' sx={{ pb:2 }} color='secondary'>
            053 981245884
          </Typography>
          <Typography variant='h4' color='secondary'>
            Email:
          </Typography>
          <Typography variant='h4' sx={{ pb:2 }} color='secondary'>
            suporte@conectave.com.br
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  )
}