import React from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api'

const containerStyle = {
  maxWidth: '900px',
  width: '100%',
  height: '650px',
  borderRadius: '8px',
  marginBottom: '22px',
}

const center = {
  lat: -31.5,
  lng: -52.1,
}

Maps.propTypes = {
  stations: PropTypes.array,
  onClick: PropTypes.func,
}

function Maps({ stations, onClick}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAk-UvcAB_fk_Q8yv2xnkcwsHgDaY22TKA"
  })

  const displayMarkers = () => (
    stations.map((st, index) => (
      <Marker 
      key={index} 
      id={index} 
      position={{
        lat: st.lat,
        lng: st.lng
      }}
      onClick={onClick} />
    ))
  )

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
      >
        {displayMarkers()}
      </GoogleMap>
  ) : <></>
}

export default React.memo(Maps)
