import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Container, Typography, Button, Box} from '@mui/material'
// components
import Page from '../../../components/Page'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  background:'#36209E',
  minHeight: '540px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '8px',
  padding: theme.spacing(2, 2.5),
}))

const StatusStyle = styled('div')(({ theme }) => ({
  maxWidth: '806px',
  width: '100%',
  maxHeight: '469px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginBottom: '22px',
}))

const CardTime = styled('div')(()=>({
  background:'#ffffff',
  maxWidth:'273px', 
  width: '100%',
  minHeight: '122px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '30px',
  padding:'13px',
  border: '2px solid #54ED8A',
  marginBottom: '12px'
}))

const Card = styled('div')(()=>({
  background:'#ffffff',
  maxWidth:'280px', 
  width: '100%',
  minHeight: '80px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  padding:'13px',
}))

RechargeStatus.propTypes = {
  chargeOngoing: PropTypes.object, 
  onClickStopCharge: PropTypes.func,
}

export default function RechargeStatus({chargeOngoing, onClickStopCharge}) {

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [localChargeOngoing] = useState({ ...chargeOngoing })

  const getTime = () => {
    const time = (Date.now() - Date.parse(localChargeOngoing.createdAt))
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
    setMinutes(Math.floor((time / 1000 / 60) % 60)) 
  }

  useEffect(() => {
    const interval = setInterval(() => getTime(localChargeOngoing.createdAt), 1000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <StatusStyle>
            <Box component="img" alt={'icon_car'} src={'/static/icons/totem_credit.svg'} sx={{ width: 72, height: 72, marginBottom:'18px' }} />
            <Typography variant='h3' color='primary'>
              Muito Bem!
            </Typography>
            <Typography  sx={{ pb:2 }}>
              Seu carro esta sendo carregado!
            </Typography>
            <CardTime>
              <Typography variant='subtitle1' color='GrayText'>
              Carregando...
              </Typography>
              <Typography variant='h2' color='primary'>
              {hours}h:{minutes}min
              </Typography>
              <Typography variant='subtitle1' color='primary'>
              Tempo de recarga
              </Typography>
            </CardTime>
            
            <Card>
              <Typography color='primary'>
              {localChargeOngoing.stationName}
              </Typography>
              {/* <Typography variant='h4' color='primary'>
              00min(R$ 00,00)
              </Typography> */}
            </Card>
          </StatusStyle>
          <Button onClick={onClickStopCharge} size='large' variant='contained' color='secondary' sx={{maxWidth: '806px', width: '100%'}}>
            Finalizar carregamento
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}