/* eslint-disable */
import React from 'react'
import { history, fetchWrapper} from '../_helpers'

const StoreContext = React.createContext({})
const { Provider, Consumer } = StoreContext

const baseUrl = 'https://api.conectave.com.br'

const withStore = BaseComponent => {
  return class extends React.Component {
    static displayName = `withStoreContext(${BaseComponent.displayName})`

    render () {
      return (
        <Consumer>
          {context => {
            return (
              <BaseComponent {...this.props} store={context} />
            )
          }}
        </Consumer>
      )
    }
  }
}

class StoreContextProvider extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      posts:[],
      user: JSON.parse(localStorage.getItem('user')) || null,
      token: JSON.parse(localStorage.getItem('token')) || '',
      charges: [],
      organizationInfo: null,
      chargeState: "station",
      chargeOngoing: null,
      chargeWaiting: null,
      stations: [],
      error: null,
      ...this.contextFunctions,
    }
  }

  get contextFunctions () {
    return {
      register: this.regiter,
      login: this.login,
      logout: this.logout,
      stationsGetAll: this.stationsGetAll,
      stationsGetByGroup:this.stationsGetByGroup,
      chargesGetByGroup: this.chargesGetByGroup,
      chargesAll: this.chargesAll,
      chargeStart: this.chargeStart,
      chargeStop: this.chargeStop,
      homePosts: this.homePosts,
      organization: this.organization,
      getChargeWaiting: this.getChargeWaiting,
      getChargeOngoing: this.getChargeOngoing,
      setChargeState: this.setChargeState,
      getChargeState: this.getChargeState,
    }
  }

  regiter = async (values) => {
    try {
      await fetchWrapper.post('', `${baseUrl}/accounts/register`, { ...values, "acceptTerms": true })

      this.setState({
        error: null,
      })

      history.navigate('/')
    }
    catch (err) {

      this.setState({
        error: err
      })

      console.log('login - ERROR', err)
    }
  }

  login = async (email, password) => {
    try {
      const userProfile = await fetchWrapper.post('', `${baseUrl}/accounts/authenticate`, { email, password })
  
      this.setState({
        error: null,
        user: userProfile,
        token: userProfile.token
      })
      localStorage.setItem('token', JSON.stringify(userProfile.token))
      localStorage.setItem('user', JSON.stringify(userProfile))

      history.navigate('/app')
    }
    catch (err) {

      this.setState({
        error: err
      })

      console.log('login - ERROR', err)
    }
  }

  logout = () => {
    try {
      this.setState({
        user: null,
        token:'',
        stations:[],
        charges:[],
        organizations: null,
        chargeOngoing: null,
        chargeWaiting: null,
        chargeState: "station",
      })
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      history.navigate('/')
    }
    catch (err) {
      console.log('login - ERROR', err)
    }
  }

  stationsGetAll = async () => {
    try {
      const stations = await fetchWrapper.get(this.state.token, `${baseUrl}/stations`)
    
      this.setState({
        stations: stations,
      })

      return stations
    }
    catch (err) {
      console.log('stationsGetAll - ERROR', err)
    }
  }

  stationsGetByGroup = async (group) => {
    try {
      const stations = await fetchWrapper.get(this.state.token, `${baseUrl}/stations/group/${group}`)
      this.setState({
        stations: stations,
      })

      return stations
    }
    catch (err) {
      console.log('stationsGetByGroup - ERROR', err)
    }
  }

  chargesGetByGroup = async (group, month,year) => {
    try{
      const payload = await fetchWrapper.get(this.state.token, `${baseUrl}/charges/group/${group}?month=${month}&year=${year}`)
      this.setState({
        charges: payload.charges.reverse(),
      })
      return payload
    }
    catch (err) {
      console.log('chargesByGroup - ERROR', err)
    }
  }

  chargesAll = async () => {
    try{
      const date = new Date(Date.now())
      const charges = await fetchWrapper.get(this.state.token, `${baseUrl}/charges?month=${date.getMonth()+1}&year=${date.getFullYear()}`)

      const chargeOngoing = charges.find(charge => charge.status === "ongoing")
    
      if(chargeOngoing) {
        this.setState({
          chargeOngoing: chargeOngoing,
        })
      }else{
        this.setState({
          chargeOngoing: null,
        })
      }

      const chargeWaiting = charges.find(charge => charge.status === "waiting")
      if(chargeWaiting) {
        this.setState({
          chargeWaiting: chargeWaiting,
        })
      }else{
        this.setState({
          chargeWaiting: null,
        })
      }


      this.setState({
        charges: charges,
      })
    }
    catch (err) {
      console.log('userCharges - ERROR', err)
    }
  }

  organization = async () => {
    try{
      const organization = await fetchWrapper.get(this.state.token, `${baseUrl}/organizations`)
      console.log(organization)
      this.setState({
        organizationInfo: organization,
      })
    } catch (err) {
      console.log('getOrganization - ERROR', err)
    }
  }

  getChargeState = async () => {
    return this.state.chargeState
  }

  getChargeWaiting = async () => {
    return this.state.chargeWaiting
  }

  getChargeOngoing = async () => {
    return this.state.chargeOngoing
    }

  getChargeState = async () => {
    return this.state.chargeState
  }

  getChargeWaiting = async () => {
    return this.state.chargeWaiting
  }

  getChargeOngoing = async () => {
    return this.state.chargeOngoing
  }

  chargeStart = async (stationUUID, code) => {
    try{
      await fetchWrapper.post(this.state.token, `${baseUrl}/charges`, {code, stationUUID, userUUID: this.state.user.uuid})
      this.setState({
        chargeOngoing: true,
      })
    }
    catch (err) {
      console.log('stationUnlock - ERROR', err)
    }
    
  }

  chargeStop = async (chargeUUID) => {
    try{
      await fetchWrapper.put(this.state.token, `${baseUrl}/charges/stop`, {chargeUUID})
      this.setState({
        chargeOngoing: null,
      })
    }catch (err) {
      console.log('userChargeStop - ERROR', err)
    }
  }

  homePosts = async () => {
    try{
      const res = await fetchWrapper.get('',`https://blog.conectave.com.br/ghost/api/content/posts?key=55bb652f4fa8e7c902a36d8d17&include=tags,authors&limit=3`)
      this.setState({
        error: null,
        posts: res.posts,
      })
    }catch (err) {
      console.log('get posts - error', err)
    }  
  }

  setChargeState = async (state) => {
    this.setState({
      chargeState: state,
    })
  }

  getChargeState = async () => {
    return this.state.chargeState
  }

  getChargeWaiting = async () => {
    return this.state.chargeWaiting
  }

  getChargeOngoing = async () => {
    return this.state.chargeOngoing
  }

  render () {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    )
  }
}

export {
  StoreContextProvider,
  withStore,
}
