import { history } from './history'

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
}

function request(method) {
    return (token, url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(token, url)
        }
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json'
            requestOptions.body = JSON.stringify(body)
        }
        return fetch(url, requestOptions).then(handleResponse)
    }
}

// helper functions

function authHeader(token, url) {
    const isLoggedIn = !!token
    const isApiUrl = url.startsWith('https://api.conectave.com.br')
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${token}` }
    } 

    return {}
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text)

        if (!response.ok) {
            if ([401, 403].includes(response.status)) {
                localStorage.removeItem('user')
                localStorage.removeItem('token')
                history.navigate('/login')
            }

            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
        }

        return data
    })
}