import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Container, Typography, Button} from '@mui/material'
// components
import Page from '../../../components/Page'
import Maps from '../../../components/Maps'
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  background:'#36209E',
  minHeight: '750px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: '8px',
}))


MapView.propTypes = {
  stations:PropTypes.array,
  onClick: PropTypes.func,
}

export default function MapView({ stations, onClick }) {
  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <Typography variant='h4' sx={{ pb:2 }} color='secondary'>
            Estações - Status
          </Typography>
          <Maps stations={stations} onClick={onClick}/>
          <Button size='large' variant='contained' color='secondary' sx={{maxWidth: '900px', width: '100%'}} component={RouterLink} to="/app/charge">
            Nova recarga
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}