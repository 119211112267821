import { useState, useEffect } from 'react'
import { Grid, Container, Typography, Select, MenuItem, Button} from '@mui/material'
import Page from '../../components/Page'
import { withStore } from '../../_store/StoreContext'
import {
  ChargesHistory,
  AppWebsiteVisits,
  AppWidgetSummary,
  
} from '../../sections/app/dashBoard'


// ----------------------------------------------------------------------

function DashboardApp(store) {
  const st = store.store
  const { stations, charges, organizationInfo } = st

  const[totalCharges, setTotalCharges]= useState("0")
  const[averageTime, setaverageTime]= useState("0")
  const[totalKw, setTotalKw]= useState("0")
  const[opt, setOpt] = useState("general")
  const[month, setMonth] = useState(new Date().getMonth()+1)
  const[year, setYear] = useState(new Date().getFullYear())
  const[chartLabels, setChartLabels] = useState([])
  const[chartData, setChartData] = useState([])
  const[group, setGroup] = useState("")

  useEffect(() => {
    _initializer()
      .catch(console.error)
  }, []);

  const _initializer = async () => {
    await st.organization()
    setGroup(Object.keys(organizationInfo.groups[0])[0])
    await st.stationsGetByGroup(Object.keys(organizationInfo.groups[0])[0]) 
    const ch = await st.chargesGetByGroup(Object.keys(organizationInfo.groups[0])[0],month,year)
    setPartials(ch)
    setChartValues(ch.charges)
  }

  const handleChangeMonth = async (event) => {
    setMonth(event.target.value)
  }

  const handleChangeYear = async (event) => {
    setYear(event.target.value)
  }

  const handleChangeGrupo = async (event) => {
    setGroup(event.target.value)
  }

  const handleApplyDate = async () => {
    updatePartials()
  }

  const handleApplyGoup = async () => {
    await st.stationsGetByGroup(group) 
    updatePartials()
  }

  const updatePartials = async () => {
    const ch = await st.chargesGetByGroup(group,month,year)
    setPartials(ch)
    setChartValues(ch.charges)
  }

  const handleSelect = (opt) => {
    setOpt(opt)
  }

  const setPartials = (payload) => {
    setTotalCharges(payload.total.toString() )
    setTotalKw(payload.totalKw)
    setaverageTime(payload.averageTime)
  }

  const setChartValues = (ch) => {
    const data = ch.reduce((acc, charge) => {
      const date = charge.createdAt.split('T')[0]
      if(!acc[date]) {
        acc[date] = 0
      }
      acc[date] += charge.stopValue
      return acc
    }, {})

    setChartLabels(Object.keys(data))
    setChartData([{
      name: "stations",
      type: 'column',
      fill: 'solid',
      data: Object.values(data)
    }])
  }

  const general = () => {
    return(
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary key="total_recargas" title="Total de Recargas" total={totalCharges} color="success" icon={'eva:charging-fill'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Tempo total de recarga" total={averageTime} color="info" icon={'ic:round-access-time-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Total de Kw/h" total={totalKw} color="info" icon={'mdi:electricity-circle'} />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <ChargesHistory
              title="Ultimas Recargas"
              list={charges.slice(0,5).map((charge) => ({
                id: charge.uuid,
                title: charge.stationName,
                description: charge.stopValue,
                image: `/static/avatars/avatar_default.jpg`,
                postedAt: charge.startDate || charge.createdAt,
              }))}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  const history = () => {
    return(
      <>
        <Grid item spacing={1}>
          <AppWebsiteVisits
            title="Histórico"
            chartLabels={chartLabels}
              chartData={chartData}
          />
          <Grid item xs={12} mt={6} md={6} lg={12}>
            <ChargesHistory
              title="Recargas"
              list={charges.map((charge) => ({
                id: charge.uuid,
                title: charge.stationName,
                description: charge.stopValue,
                image: `/static/avatars/avatar_default.jpg`,
                postedAt: charge.startDate || charge.createdAt,
              }))}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
          Operação - {`${organizationInfo?.label || ''}`}
        </Typography>
        <Grid sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Grupo 
        </Typography>
          <Select
            labelId='select-grupo'
            id='select-grupo'
            value={group} 
            onChange={handleChangeGrupo}
            sx={{ mr:2, width:'390px'}}
          >
            <MenuItem value={0}>Selecione o grupo</MenuItem>
            {organizationInfo?.groups.map((item) => (
              <MenuItem
                key={`${Object.keys(item)[0]}-${Object.values(item)[0]}`}
                value={`${Object.keys(item)[0]}`}
              >
                {Object.values(item)[0]}
              </MenuItem>
            ))}
          </Select>
          <Button 
            size="large"
            variant='contained' 
            color="secondary" 
            sx={{mr: 2, mb:'8px', maxWidth: '200px', width: '100%'}}
            onClick={() => handleApplyGoup(group)}
          >
            Aplicar
          </Button>
        </Grid>
        <Typography variant="h4" sx={{ mb: 3 }}>
              Estações
        </Typography>

        <Grid container spacing={3}sx={{ mb: 2 }}>
          {stations.map((item) => (
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title={item.status==="available" ? "Disponivel" : "Ocupada"} total={`${item.name} - ${item.type}`} color={item.status==="available" ? "success" : "warning"} icon={'mdi:ev-station'} />
            </Grid>
          ))}
        </Grid>

        <Typography variant="h4" sx={{ mb: 3 }}>
            Recargas 
        </Typography>
        
        <Grid sx={{ mb: 2 }}>
          <Button 
            size="large"
            variant='contained' 
            color="secondary" 
            sx={{mr: 2, mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={() => handleSelect("general")}
          >
            Geral
          </Button>
          <Button 
            size="large"
            variant='contained' 
            color="secondary" 
            sx={{mr: 2, mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={() => handleSelect("historic")}
            >
            Histórico
          </Button>
        </Grid>

        <Grid sx={{ mb: 2 }}>
          <Select
            labelId='select-month'
            id='select-month'
            value={month} 
            onChange={handleChangeMonth}
            sx={{ mr:2, mb:4, width:'120px'}}
          >
            <MenuItem value={0}>Selecione o mês</MenuItem>
            {[{name:"jan", id:1},
              {name:"fev",id:2},
              {name:"mar",id:3},
              {name:"abr",id:4},
              {name:"mai",id:5},
              {name:"jun",id:6},
              {name:"jul",id:7},
              {name:"ago",id:8},
              {name:"set",id:9},
              {name:"out",id:10},
              {name:"nov",id:11},
              {name:"dez",id:12}].map((item) => (
              <MenuItem
                key={`${item.name}-${item.id}`}
                value={`${item.id}`}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId='select-year'
            id='select-year'
            value={year} 
            onChange={handleChangeYear}
            sx={{ mr:2, mb:4, width:'130px'}}
          >
            <MenuItem value={0}>Selecione o ano</MenuItem>
            {[{name:"2022", id:2022},{name:"2023", id:2023},{name:"2024", id:2024}].map((item) => (
              <MenuItem
                key={`${item.name}-${item.id}`}
                value={`${item.id}`}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <Button 
            size="large"
            variant='contained' 
            color="secondary" 
            sx={{mr: 2, mb:'8px', maxWidth: '200px', width: '100%'}}
            onClick={() => handleApplyDate()}
          >
            Aplicar
          </Button>
        </Grid>

        {opt === "general"? general() : history()}
        
      </Container>
    </Page>
  )
}



export default withStore(DashboardApp)
