import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Typography } from '@mui/material'
// components
import Page from '../../../components/Page'


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  minHeight: '600px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '8px',
  padding: theme.spacing(2, 2.5),
}))

const StationStyle = styled('div')(({ theme }) => ({
  maxWidth: '900px',
  width: '100%',
  maxHeight: '475px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginBottom: '22px',
}))

export default function RechargeConect() {
  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <StationStyle>
            <Typography variant='h4' sx={{ mt:3, mb:2, pb:2 }} color='primary'>
              Estação desbloqueada!!!
            </Typography>
            <Typography variant='h6' sx={{ mb:3, pb:2 }}>
              Conecte o carregador ao veiculo.
            </Typography>
            <img src="/static/illustrations/station_car.png" alt="conect"/>
            <Typography sx={{ mt:4 }}>
              O carregamento do seu carro começará em instantes! Agora é só aguardar.
            </Typography>
          </StationStyle>
        </ContentStyle>
      </Container>
    </Page>
  )
}