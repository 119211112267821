import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Termo() {
  return (
    <Page title="Termo">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Termos de serviço e Politica de Privacidade.
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>

            TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS DE ACORDO COM A LEI GERAL DE PROTEÇÃO DE DADOS - LGPD

            Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o tratamento de seus dados pessoais para finalidade específica, em conformidade com a Lei nº 13.709 - Lei Geral de Proteção de Dados Pessoais (LGPD).

            Ao manifestar sua aceitação para com o presente termo, o Titular consente e concorda que Conecta VE, inscrita no CNPJ sob o n° 47.824.177/0001-76, com sede na Rua Simon Bolívar, n°1205 / BOUGAINVILLE - Laranjal, CEP: 96083470, e-mail: contato@conectave.com.br, doravante denominada Controladora¹ , realize o tratamento de dados fornecidos.

            A Conecat VE destaca que está empenhada em salvaguardar a sua privacidade ao estabelecer esta relação coma empresa. Este termo tem a finalidade de deixar o mais claro possível a nossa política de coleta e compartilhamento de dados, informando sobre os dados coletados e como os utilizamos.

            Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO² para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessários à prestação de nossos serviços, tais como:

            Informações que você oferece: Coletamos os dados fornecidos por você no cadastro, tais como nome e sobrenome, e-mail, telefone, CPF e demais informações requeridas no cadastro.

            Informações sobre pagamentos: Ao submeter seus dados para pagamento, podemos coletar informações sobre a compra ou transação. Isso abrange suas informações de pagamento, como os dados de seu cartão de crédito ou débito, informações de conta e autenticação, além dos dados de faturamento, tais como endereço completo, CPF e CNPJ.


            ¹Art. 5º Para os fins desta Lei, considera-se: [...] VI - controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;
            ² Art. 7º O tratamento de dados pessoais somente poderá ser realizado nas seguintes hipóteses:
            I - mediante o fornecimento de consentimento pelo titular;



            FINALIDADES: UTILIZAÇÃO DESTAS INFORMAÇÕES

            Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso destas informações para as seguintes FINALIDADES³ :

            Fornecer, melhorar e desenvolver os serviços disponíveis - usamos os seus dados para melhorar a qualidade dos nossos serviços, envio de orçamentos, atendimento de solicitações e dúvidas, identificar corretamente o usuário, envio de comunicações de oferta, entrar em contato com cliente (quando necessário).

            Obrigações legais - compartilhamos seus dados com terceiros para cumprir exigências legais, regulatórias ou fiscais, envolvendo a divulgação de seus dados pessoais a terceiros, tribunal, órgãos reguladores ou agências governamentais.

            COMPARTILHAMENTO DE DADOS

            O controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709.

            SEGURANÇA DOS DADOS

            A Conecta VE emprega medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.
            Ainda que a Conecta VE adote tudo o que está ao seu alcance para evitar incidentes de segurança, é possível que ocorra algum problema motivado exclusivamente por um terceiro - como em caso de ataques de hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que tratamos, nos eximimos de responsabilidade caso ocorra uma situação excepcional como essas, sobre as quais não temos nenhum tipo de controle⁴ .

            De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade com o disposto na Lei Geral de Proteção de Dados⁵ .


            ³ Art. 6º As atividades de tratamento de dados pessoais deverão observar a boa-fé e os seguintes princípios:
            I - finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao titular, sem possibilidade detratamento posterior de forma incompatível com essas finalidades;
            ⁴ Art. 43. Os agentes de tratamento só não serão responsabilizados quando provarem: [...]
            III - que o dano é decorrente de culpa exclusiva do titular dos dados ou de terceiro.
            ⁵ Art. 48. O controlador deverá comunicar à autoridade nacional e ao titular a ocorrência de incidente de segurança que possa acarretarrisco ou dano relevante aos titulares.



            TÉRMINO DO TRATAMENTO DOS DADOS

            Os dados pessoais coletados pelo site são armazenados e utilizados por período de tempo que corresponda ao necessário para atingir as finalidades elencadas neste documento e que considere os direitos de seus titulares, os direitos do controlador do site e as disposições legais ou regulatórias aplicáveis.
            O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer momento, que sejam eliminados os dados pessoais não anonimizados do Titular (art. 18, LGPD). Destaca-se que essa solicitação dependerá de análise prévia sobre as disposições legais e regulatórias aplicáveis.
            O Titular fica ciente de que poderá ser inviável ao Controlador continuar o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos dados pessoais

            DIREITOS DO TITULAR

            O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer momento e mediante requisição (art. 18, LGPD):

            Confirmação da existência de tratamento de dados pessoais.

            Acesso aos dados pessoais.

            Revogação do consentimento.

            Correção de dados pessoais incompletos, inexatos ou desatualizados.

            Eliminação dos dados pessoais tratados com o consentimento ou desnecessários,
            excessivos ou quando entender que algum ponto da LGPD não foi atendido.

            Todas as solicitações serão tratadas de forma gratuita, e serão submetidas a uma prévia avaliação da sua identidade e da viabilidade do atendimento, a fim de cumprir com eventuais obrigações que impeçam o completo atendimento das requisições dos titulares de direito.

            DIREITO DE REVOGAÇÃO DO CONSENTIMENTO

            Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via e-mail ou correspondência ao Controlador:

            E-mail: contato@conectave.com.br
            Endereço Postal: Rua Simon Bolívar, n°1205 / BOUGAINVILLE - Laranjal, Pelotas, CEP: 96083470,

            Conforme acima exposto, a solicitação passará por uma avaliação prévia de viabilidade, a fim de verificar eventuais obrigações que impeçam o completo atendimento das requisições dos titulares de direito.

            Permanecemos sempre à disposição para esclarecer suas dúvidas.

          </Typography>
          <Button to="/register" size="large" variant="contained" component={RouterLink}>
            Voltar para a pagina de registro.
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
