import { useEffect } from 'react'
// @mui
import { styled } from '@mui/material/styles'
import { Container } from '@mui/material'
// components
import Page from '../../components/Page'
// sections
import HomeNavbar from '../../sections/public/home/HomeNavbar'
import HomeFooter from '../../sections/public/home/HomeFooter'
import IntroNew from '../../sections/public/home/intro/IntroNew'
import HowWorks from '../../sections/public/home/howWorks/HowWorks'
import Station from '../../sections/public/home/station/Station'
// import Blog from '../../sections/public/home/blog/Blog'
import PlanAndPrices from '../../sections/public/home/planAndPrices/PlanAndPrices'
// store 
import { withStore } from '../../_store/StoreContext'

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 64

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 14,
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 14,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))



// ----------------------------------------------------------------------

function HomePublic(store) {
  const st = store.store
  const { posts } = st

  useEffect(() => {
    st.homePosts() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])


  return (
    <RootStyle>
      <HomeNavbar/>
      <MainStyle>
        <Page title="home">
          <Container maxWidth="xxl">
            <IntroNew/>
            <PlanAndPrices/>
            <HowWorks/>
            <Station/>
            <HomeFooter/>
          </Container>
        </Page>
      </MainStyle>
    </RootStyle>
    
  )
}

export default withStore(HomePublic)
