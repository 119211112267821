// component
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

const navConfig = [
  {
    title: 'Estações',
    path: '/app/stations',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'Carregar',
    path: '/app/charge',
    icon: getIcon('eva:charging-fill'),
  },
  // {
  //   title: 'Creditos',
  //   path: '/app/shop',
  //   icon: getIcon('eva:credit-card-fill'),
  // },
  {
    title: 'Suporte',
    path: '/app/support',
    icon: getIcon('eva:settings-2-fill'),
  }
]

const navConfigValidate = (navConfig) => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.roles?.includes('partner')) {
    navConfig.push({
      title: 'Operação',
      icon: getIcon('eva:person-fill'),
      path: '/app/dashboard',
    })
  }
  return navConfig
}

export default navConfigValidate(navConfig)
