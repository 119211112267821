import { useEffect } from 'react'
import MapView from '../../sections/app/stations/MapView'

import { withStore } from '../../_store/StoreContext'

function AppStations(store) {
  const st = store.store

  useEffect(() => {
    _initializer()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _initializer = async () => {
    await st.stationsGetAll()
    // await st.userChargeOngoing()
  }

  const handleNewCharge = async () => {
  //   console.log('new_charge', item)
  }

  return (
    <MapView stations={st.stations} onClick={handleNewCharge}/>
  )
}

export default withStore(AppStations)
