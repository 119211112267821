// @mui
import { styled } from '@mui/material/styles'
import { Stack, Box } from '@mui/material'

// ----------------------------------------------------------------------

const ContentStyle= styled('div')(({ theme }) => ({
  background:'#36209E',
  height: '202px',
  paddingLeft: '100px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '400px',
  }
}))

// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <ContentStyle>
      <Stack direction="column" alignItems="letf" sx={{ my: 2 }}>
        <Box component="img" src="/static/conectave-logo-blue.png" sx={{ width: '305px', height:'130px', px: 2.5, py: 3, display: 'inline-flex' }}/>
        <p style={{color:'#ffffff'}} >© 2022 conectave</p>
      </Stack>
    </ContentStyle>
  )
}
