import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

import { history } from '../_helpers'

PrivateRoute.propTypes = {
    children: PropTypes.node.isRequired,
}

function PrivateRoute({ children }) {
    const token = JSON.parse(localStorage.getItem('token'))
    if (!token) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/" state={{ from: history.location }} />
    }

    // authorized so return child components
    return children
}

export default PrivateRoute
