// @mui
import { styled } from '@mui/material/styles'
import { Stack, Typography } from '@mui/material'

// ----------------------------------------------------------------------

const ContentStyle= styled('div')(({ theme }) => ({
  background:'#ffffff',
  height: '500px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 2),
  minHeight: '50vh',
  [theme.breakpoints.up('lg')]: {
    height: '790px',
  }
}))

// ----------------------------------------------------------------------

export default function Station() {
  return (
    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
      <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Typography variant="h3" sx={{ color:'#36209E'}}>
          Estações
        </Typography>
        <Typography sx={{maxWidth:550, fontSize:16, marginBottom:8, color:'#737373'}} >
          Atualmente nossas estações estão localizadas no Rio Grande do Sul.
        </Typography>
        <img src="/static/illustrations/map_stations.png" alt="intro" />
      </Stack>
    </ContentStyle>
  )
}
