import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// components
import Iconify from '../../../../components/Iconify'
import { FormProvider, RHFTextField } from '../../../../components/hook-form'
// store 
import { withStore } from '../../../../_store/StoreContext'

// ----------------------------------------------------------------------

function LoginForm(store) {
  const navigate = useNavigate()
  const st = store.store

  useEffect(() => {
    // redirect to home if already logged in
    if (JSON.parse(localStorage.getItem('token'))) navigate('/app', { replace: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email deve ser um email válido').required('Obridgatório'),
    // password: Yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&/)/(]{6,}$/, 'Senha inválida').required('Obrigatório'),
    password: Yup.string().min(6, 'Senha inválida').required('Obrigatória'),

  })

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async ({ email, password }) => {
    await st.login(email, password)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email" />
        <RHFTextField
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Mantenha-me conectado" /> */}
        {/* <Link variant="subtitle2" underline="hover">
          Esqueceu sua senha?
        </Link> */}

        {st.error &&
          <div style={{color:'#ff0000'}} className="alert alert-danger mt-3 mb-0">{st.error}</div>
        }
      </Stack>
      <LoadingButton sx={{ mb:"8px" }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
      
    </FormProvider>
  )
}

export default withStore(LoginForm)