import * as Yup from 'yup'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// components
import { FormProvider, RHFTextField, RHFTextFieldPhone } from '../../../../components/hook-form'
// store 
import { withStore } from '../../../../_store/StoreContext'

function RegisterForm(store) {
  const st = store.store

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('Obrigatório'),
    lastName: Yup.string().required('Obrigatório'),
    email: Yup.string().email('Email deve ser um email válido').required('Obrigatório'),
    phone: Yup.string().matches(/^\(\d{2}\)\d{5}-\d{4}$/gi, 
      "(xx)xxxx-xxxx").required('Obrigatório'),
    password: Yup.string().min(6, 'Senha muito curta.').required('Obrigatória'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Deve ser igual a senha.').required('Obrigatóro'),
  })

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (values) => {
    await st.register(values)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="Nome" />
          <RHFTextField name="lastName" label="Sobrenome" />
        </Stack>

        <RHFTextField name="email" label="Email" />
        <RHFTextFieldPhone name="phone" label="Celular" />

        <RHFTextField
          name="password"
          label="Senha"
        />
        <RHFTextField
          name="confirmPassword"
          label="Confirme a senha"
        />

        {st.error &&
          <div style={{color:'#ff0000'}} className="alert alert-danger mt-3 mb-0">{st.error}</div>
        }

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Registrar
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}

export default withStore(RegisterForm)
