import PropTypes from 'prop-types'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Typography, Select, MenuItem, TextField, Button} from '@mui/material'
// components
import Page from '../../../components/Page'


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  minHeight: '600px',
  minWidth: '255px',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  borderRadius: '8px',
  padding: theme.spacing(2, 2.5),
}))

const StationStyle = styled('div')(({ theme }) => ({
  maxWidth: '900px',
  width: '100%',
  maxHeight: '437px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginBottom: '22px',
}))

RechargeStation.propTypes = {
  stations: PropTypes.array,
  stationID: PropTypes.string, 
  setStationID: PropTypes.func,
  setCode: PropTypes.func,
  onCancel: PropTypes.func,
  onClickUnlock: PropTypes.func,
  error: PropTypes.object,
}

export default function RechargeStation({
  stations,
  stationID, 
  setStationID,
  setCode, 
  onCancel, 
  onClickUnlock,
  error,
}) {
  const handleChangeLocation = (event) => {
    setStationID(event.target.value)
  }

  const handleCode = (event) => {
    const code = event.target.value
    if(code.length > 2) {
      error = null
    }
    setCode(event.target.value)
  }

  return (
    <Page title='AppCharge' sx={{ pt:0 }}>
      <Container maxWidth='xl'>
        <ContentStyle >
          <StationStyle>
            <Typography variant='h3' sx={{ mb:4, pb:2 }} color='primary'>
              Dados da recarga
            </Typography>
            <Typography variant='h6' sx={{ pb:1 }} >
              Selecione a estação
            </Typography>
            <Select
              labelId='select-station'
              id='select-station'
              value={stationID} 
              onChange={handleChangeLocation}
              sx={{ mb:4, width:'278px'}}
              error={error}
            >
              <MenuItem value={0}>Selecione a estação</MenuItem>
              {stations.map((station) => (
                <MenuItem
                  key={`${station.group}-${station.uuid}`}
                  value={`${station.uuid}`}
                >
                  {station.name}
                </MenuItem>
              ))}
            </Select>
            <Typography variant='h6' sx={{ pb:1 }} >
              Insira o codigo da estação*
            </Typography>
            <TextField 
              id='outlined-basic' 
              type="number"
              placeholder='Digite o código de desbloqueio' 
              variant='outlined'
              onChange={handleCode} 
              sx={{ mb:1, width:'278px'}}
            />
            <Typography variant='h8' sx={{ pb:1 }} >
              *O codigo da estação se encontra logo abaixo da identificação da mesma.
            </Typography>
          </StationStyle>
          <Button 
            size='medium' 
            variant='contained' 
            color="secondary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onClickUnlock}
            disabled={!setCode}
          >
            Desbloquear
          </Button>

          <Button 
            size='medium' 
            variant='outlined' 
            color="primary" 
            sx={{mb:'8px', maxWidth: '403px', width: '100%'}}
            onClick={onCancel}
            >
            X Cancelar
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}